import React, { Component } from 'react';

class Blog extends Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default Blog;
